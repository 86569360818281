export interface User {
  id: string
  role: UserRole
  created_at: string
  status: UserStatus
  stripe_id: string
  email?: string
  type?: UserType
  phone_number?: string
  otp?: string
  pics?: string[]
  fake_reporting_count?: number
  accesses_count: number
}
export interface Admin extends User {
  first_name: string
  last_name: string
  fake_reporting_count: undefined
}
export interface Artist extends User {
  first_name: string
  last_name: string
  nationality: string
  address: Address
  artist_sex: Sex
  sexual_orientation: SexualOrientation
  types: ArtistType[]
  extra_roles?: ArtistExtraRole[]
  available_for_clubs: ClubType[]
  languages: string[]
  birthday: string
  height_cm: number
  weight_kg: number
  eyes_color?: EyesColor
  hair_color?: HairColor
  artist_nationality_edit_requested_at: Date | null
  skin_color?: SkinColor
  clothes_size?: ClothesSize
  shoes_size?: ShoesSize
  favorite_stylist?: string
  favorite_parfume?: string
  last_location?: GeoPoint
}
export interface Manager extends User {
  first_name: string
  last_name: string
  company?: string
  nationality: string
  address: Address
  manager_sex: ManagerSex
  extra_roles?: ArtistExtraRole[]
  club_types: ClubType[]
  manager_nationality_edit_requested_at: Date | null
  free_trial_requested_at: Date | null
  birthday: string
  last_location?: GeoPoint
  subscription?: Subscription
}
export interface Club extends User {
  name: string
  address: Address
  club_type: ClubType
  allowed_types: ArtistType[]
  allowed_sexes: Sex[]
  allowed_orientations: SexualOrientation[]
  allowed_extra_roles: ArtistExtraRole[]
  business_days?: Day[]
  club_type_edit_requested_at: Date | null
  club_address_edit_requested_at: Date | null
  free_trial_requested_at: Date | null
  daily_salary?: ClubDailySalary
  payment_frequency?: ClubSalaryFrequency
  accomodation?: ExtraServiceType
  driver_service?: ExtraServiceType
  minimum_stay?: string
  consummation?: Consummation
  subscription?: Subscription
}
export enum UserStatus {
  ENABLED = 'enabled',
  BLOCKED = 'blocked',
}
export enum UserRole {
  USER = 'user',
  ADMIN = 'admin',
  SUPER_ADMIN = 'super_admin',
}
export enum UserType {
  ARTIST = 'artist',
  CLUB = 'club',
  MANAGER = 'manager',
}
export enum ClubType {
  NIGHT_CLUB = 'night_club',
  LAP_DANCE = 'lap_dance',
  SWINGER_CLUB = 'swinger_club',
  DISCO = 'disco',
}
export enum Sex {
  MALE = 'male',
  FEMALE = 'female',
}
export enum ManagerSex {
  MALE = 'male',
  FEMALE = 'female',
  COMPANY = 'company',
}
export enum SexualOrientation {
  HETEROSEXUAL = 'heterosexual',
  HOMOSEXUAL = 'homosexual',
  BISEXUAL = 'bisex',
  TRANSSEXUAL = 'transsexual',
}
export enum ArtistType {
  DJ = 'dj',
  DANCER = 'dancer',
  HOSTESS = 'hostess',
  GIGOLO = 'gigolo',
  MUSICIAN = 'musician',
  SINGER = 'singer',
}
export enum ArtistExtraRole {
  DRAG_QUEEN = 'drag_queen',
  PORNO_STAR = 'porno_star',
  SEXY_STAR = 'sexy_star',
}
export enum EyesColor {
  BLUE = 'blue',
  BROWN = 'brown',
  GREEN = 'green',
  BLACK = 'black',
  GREY = 'grey',
  LIGHT_BLUE = 'light_blue',
}
export enum HairColor {
  BRUNETTE = 'brunette',
  WHITE = 'white',
  BLONDE = 'blonde',
  REDHEAD = 'redhead',
  PINK = 'pink',
  SHAVED = 'shaved',
  BALD = 'bald',
  BLUE = 'blue',
  BLACK = 'black',
}
export enum SkinColor {
  VERY_LIGHT = 'very_light',
  LIGHT = 'light',
  MEDIUM_LIGHT = 'medium_light',
  MEDIUM_DARK = 'medium_dark',
  DARK = 'dark',
  VERY_DARK = 'very_dark',
}
export enum ClothesSize {
  XXS = 'xxs',
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
  XXL = 'xxl',
}
export enum ShoesMeasureUnit {
  EU = 'eu',
}
export enum BirthdayVisibility {
  LONG = 'long',
  MEDIUM = 'medium',
  SHORT = 'short',
}
export interface Address {
  address?: string
  country: Country
  state: State
  city: City
}
export interface Birthday {
  date: string
  visibility: BirthdayVisibility
}
export interface ShoesSize {
  value: number
  measure_unit: ShoesMeasureUnit
}
export interface ClubDailySalary {
  type: ClubDailySalaryType
  value?: string
}
export interface ClubSalaryFrequency {
  type: ClubSalaryFrequencyType
  notes?: string
}
export enum Day {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'sarturday',
  SUNDAY = 'sunday',
}
export enum ClubDailySalaryType {
  YES = 'yes',
  NO = 'no',
  REQUEST_INFO = 'request_info',
}
export enum ClubSalaryFrequencyType {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  NEXT_WEEK = 'next_week',
  FORTNIGHT = 'fortnight',
  MONTHLY = 'monthly',
  REQUEST_INFO = 'request_info',
}
export enum ExtraServiceType {
  FREE = 'free',
  FOR_A_FEE = 'for_a_fee',
  NONE = 'none',
}
export enum Consummation {
  ACTIVE = 'active',
  PASSIVE = 'passive',
}
export interface UserUpdate {
  email?: string
  password?: string
  otp?: string
}
export interface GeoPoint {
  longitude: number
  latitude: number
  created_at: string
}
export interface Country {
  id: string
  name: string
  code: string
  flag: string | null
}
export interface State {
  id: string
  name: string
  code: string
  country_id: string
}
export interface City {
  id: string
  name: string
  country_id: string
  state_id: string
}
export interface Subscription {
  id: string
  stripe_id: string
  type: SubscriptionType
  status: SubscriptionStatus
  customer: User
  created_at: Date
}

export enum SubscriptionType {
  BASIC_MONTHLY = 'basic_monthly',
}

export enum SubscriptionStatus {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  PAST_DUE = 'past_due',
  TRIALING = 'trialing',
  UNPAID = 'unpaid',
}
