import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Table, Ordering, Button, Paginator, Column, Subtitle } from '@duckma/react-ds'
import { toast } from 'react-toastify'

import { api } from '../../data/api'
import { useRequest } from '../../hooks/useRequest'
import { Club, UserType } from '../../data/models_users'
import { SearchField } from '../../components/SearchField'
import { SkeletonTable } from '../../components/SkeletonTable'
import { dateTimeFormatter } from '../../utils/formatters'
import { formatUserAddress } from './utils/UserUtils'
import { renderClubType, renderClubBusinessDays } from './utils/ClubUtils'
import { SubscriptionStatusTag } from './components/SubscriptionStatusTag'

export const ClubsPage = () => {
  const history = useHistory()
  const [getUsers, { status, data }] = useRequest(api.getUsers)
  const [deleteUser, { data: deleteData }, resetDelete] = useRequest(api.deleteUser)

  const [ordering, setOrdering] = useState<Ordering<Club>>({
    key: 'created_at',
    direction: 'asc',
  })
  const [search, setSearch] = useState<string | undefined>(undefined)
  const [page, setPage] = useState<number>(0)

  const getUsersWithParams = useCallback(
    () =>
      getUsers({
        type: [UserType.CLUB],
        order_by: ordering.key,
        order_direction: ordering.direction,
        search_text: search,
        page,
        page_size: 10,
      }),
    [getUsers, ordering, page, search]
  )

  useEffect(() => {
    getUsersWithParams()
  }, [getUsersWithParams])

  useEffect(() => {
    if (deleteData != null) {
      toast('Utente eliminato con successo', { type: 'success' })
      getUsersWithParams()
      resetDelete()
    }
  }, [deleteData, resetDelete, getUsersWithParams])

  const columns = useMemo(
    () =>
      [
        {
          key: 'actions',
          name: 'Azioni',
          orderable: false,
          render: (user) => (
            <Actions>
              <Button
                size="icon"
                iconLeft="info"
                color="primary"
                radius={24}
                onClick={() => {
                  history.push('/users/' + user.id)
                }}
              />
              <Button
                size="icon"
                iconLeft="trash"
                color="danger"
                radius={24}
                onClick={() => {
                  const conf = window.confirm(
                    `Sei sicuro di voler cancellare l'utente (${user.email})? L'azione è irreversibile.`
                  )
                  if (conf) {
                    deleteUser({ id: user.id })
                  }
                }}
              />
            </Actions>
          ),
        },
        { key: 'accesses_count', name: 'Accessi', orderable: true },
        {
          name: 'Richieste',
          render: (user) => (
            <Actions>
              {[
                user.club_address_edit_requested_at,
                user.club_type_edit_requested_at,
                user.free_trial_requested_at,
              ].some((flag) => flag) && (
                <Button
                  size="icon"
                  iconLeft="warning"
                  color="warning"
                  radius={24}
                  onClick={() => {
                    history.push('/users/' + user.id)
                  }}
                />
              )}
            </Actions>
          ),
        },
        { key: 'name', name: 'Nome', orderable: true },
        {
          key: 'club_type',
          name: 'Tipologia',
          orderable: true,
          render: renderClubType,
        },
        { key: 'phone_number', name: 'Telefono', orderable: true },
        {
          key: 'business_days',
          name: 'Giorni di apertura',
          orderable: false,
          render: renderClubBusinessDays,
        },
        {
          key: 'country',
          name: 'Nazione',
          orderable: true,
          render: (user) => formatUserAddress(user, 'country'),
        },
        {
          key: 'state',
          name: 'Regione',
          orderable: true,
          render: (user) => formatUserAddress(user, 'state'),
        },
        {
          key: 'city',
          name: 'Città',
          orderable: true,
          render: (user) => formatUserAddress(user, 'city'),
        },
        {
          key: 'created_at',
          name: 'Data Creazione',
          orderable: true,
          render: (user) => dateTimeFormatter(user.created_at),
        },
        {
          key: 'subscription_status',
          name: 'Abbonamento',
          orderable: false,
          render: (user) => <SubscriptionStatusTag status={user.subscription?.status} />,
        },
      ] as Column<Club>[],
    [deleteUser, history]
  )

  return (
    <Container>
      <SearchField
        style={{ alignSelf: 'flex-end', marginBottom: '35px' }}
        onSearch={(search) => {
          if (page !== 0) {
            setPage(0)
          }
          setSearch(search)
        }}
      />
      {status !== 'loading' ? (
        <TableContainer>
          <Table
            columns={columns}
            records={data ? (data.items as Club[]) : []}
            order={ordering}
            onOrder={setOrdering}
          />
        </TableContainer>
      ) : (
        <SkeletonTable />
      )}
      {status === 'success' && data && data.items.length > 0 && (
        <Paginator
          style={{ marginTop: '75px', marginBottom: '75px' }}
          currentPage={page}
          totalPages={data.pagination.total_pages}
          onPageSelect={setPage}
        />
      )}
      {status === 'success' && data && data.items.length === 0 && (
        <Subtitle text="Nessun utente trovato" color="gray100" />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 50px;
  padding-right: 50px;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  * {
    margin-right: 16px;
  }
`

const TableContainer = styled.div`
  min-width: 200px;
  background-color: #37afb8;
  max-width: calc(100vw - 280px);
  overflow-x: scroll;
  overflow-y: hidden;
  margin-right: 10px;
  margin-left: 10px;
  border: 1px solid #cacaca;
  border-radius: 10px;
  th {
    color: #fff !important;
  }
`
