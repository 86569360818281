import React, { useCallback, useEffect } from 'react'
import { Button, Input } from '@duckma/react-ds'

import { api } from '../../../data/api'
import { ControlledField } from '../../../components/ControlledForm'
import { dateFormatter } from '../../../utils/formatters'
import { requiredValidator } from '../../../utils/validators'
import { Artist } from '../../../data/models_users'
import { formatUserAddress, formatUserGeoLocation } from '../utils/UserUtils'
import {
  formatArtistSex,
  formatArtistTypes,
  formatArtistLanguages,
  formatArtistHeight,
  formatArtistEyesColor,
  formatArtistWeight,
  formatArtistSkinColor,
  formatArtistShoesSize,
} from '../utils/ArtistUtils'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useRequest } from '../../../hooks/useRequest'

import { getUserType } from '../utils/UserUtils'
import { storage } from '../../../data/storage'

export const ArtistFields: React.FC<{ artist: Artist }> = ({ artist }) => {
  const [acceptEditRequest, { status }] = useRequest(api.acceptArtistNationalityEditRequest)

  const acceptRequest = useCallback(() => {
    acceptEditRequest({
      id: artist.id,
    })
  }, [acceptEditRequest, artist.id])

  useEffect(() => {
    if (status === 'success') {
      toast.success('Richiesta accettata con successo')
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  }, [status])

  return (
    <React.Fragment>
      {artist.artist_nationality_edit_requested_at && (
        <RequestsForm>
          <UserRequest>
            L'artista{' '}
            <b>
              {' '}
              {artist.first_name} {artist.last_name}
            </b>{' '}
            ha richiesto di poter cambiare la propria <b>nazionalità</b>
          </UserRequest>
          <AcceptRequestButton
            size="large"
            radius={10}
            text="Accetta"
            type="button"
            onClick={() => acceptRequest()}
          />
        </RequestsForm>
      )}
      <ControlledField
        name="nome"
        fieldName="first_name"
        validator={requiredValidator()}
        disabled
      />
      <ControlledField
        name="cognome"
        fieldName="last_name"
        validator={requiredValidator()}
        disabled
      />
      <ControlledField name="gender" fieldName="sex" disabled formatter={formatArtistSex} />
      <ControlledField name="tipologia" fieldName="types" disabled formatter={formatArtistTypes} />
      <ControlledField name="numero-di-telefono" fieldName="phone_number" disabled />
      <Input
        name="nazione"
        disabled={getUserType(storage.getUser()) !== 'admin'}
        value={formatUserAddress(artist, 'country')}
      />
      <Input
        name="regione"
        disabled={getUserType(storage.getUser()) !== 'admin'}
        value={formatUserAddress(artist, 'state')}
      />
      <Input
        name="città"
        disabled={getUserType(storage.getUser()) !== 'admin'}
        value={formatUserAddress(artist, 'city')}
      />
      <ControlledField
        name="lingue-parlate"
        fieldName="languages"
        disabled
        formatter={formatArtistLanguages}
      />
      <ControlledField
        name="data-di-nascita"
        fieldName="birthday"
        disabled
        formatter={dateFormatter}
      />
      <ControlledField
        name="altezza"
        fieldName="height_cm"
        disabled
        formatter={formatArtistHeight}
      />
      <ControlledField
        name="occhi"
        fieldName="eyes_color"
        disabled
        formatter={formatArtistEyesColor}
      />
      <ControlledField name="peso" fieldName="weight_kg" disabled formatter={formatArtistWeight} />
      <ControlledField name="taglia-vestiti" fieldName="clothes_size" disabled />
      <ControlledField
        name="pelle"
        fieldName="skin_color"
        disabled
        formatter={formatArtistSkinColor}
      />
      <Input name="taglia-scarpe" value={formatArtistShoesSize(artist.shoes_size)} disabled />
      <ControlledField name="stilista-preferito" fieldName="favorite_stylist" disabled />
      <ControlledField name="profumo-preferito" fieldName="favorite_parfume" disabled />
      <ControlledField
        name="data-di-iscrizione"
        fieldName="created_at"
        disabled
        formatter={dateFormatter}
      />
      <Input name="ultima-posizione" value={formatUserGeoLocation(artist)} disabled />
    </React.Fragment>
  )
}

const RequestsForm = styled.div`
  width: 100%;
  padding: 10px 16px;
  flex-basis: 100% !important;
  display: flex;
  flex-flow: wrap;
`

const UserRequest = styled.p`
  font-size: 16px;
  font-family: open sans;
  width: 30%;
  margin-right: 40px;
  line-height: 23px;
`

const AcceptRequestButton = styled(Button)`
  width: 120px;
  height: 50px;
`
