import React, { useState } from 'react'
import styled from 'styled-components'

import { Button, Input } from '@duckma/react-ds'
import LogoImg from '../../images/logo.png'
import { Link } from './components/Link'
import { useRequest } from '../../hooks/useRequest'
import { api } from '../../data/api'
import { Redirect } from 'react-router-dom'

export const LoginPage = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [request, { status, data }] = useRequest(api.login)

  return (
    <Container
      onSubmit={(e) => {
        e.preventDefault()
        request({ email, password })
      }}
    >
      {data && <Redirect to="/" />}
      <Logo src={LogoImg} alt="Logo" />
      <Input
        name="email"
        type="email"
        label=""
        placeholder="Inserisci indirizzo email"
        size="large"
        value={email}
        onChange={setEmail}
        style={{ marginBottom: '30px' }}
      />
      <Input
        name="password"
        type="password"
        label=""
        placeholder="Inserisci password"
        size="large"
        value={password}
        onChange={setPassword}
        style={{ marginBottom: '30px' }}
      />
      <Link to="/password-reset" text="Password dimenticata?" />
      <Button
        text="Login"
        radius={4}
        loading={status === 'loading'}
        style={{ width: '50%', marginTop: '50px' }}
      />
    </Container>
  )
}

const Container = styled.form`
  width: 40%;
  padding-left: 30%;
  padding-right: 30%;
  padding-top: 10vh;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const Logo = styled.img`
  width: 30vw;
  object-fit: contain;
`
