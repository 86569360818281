import React, { useEffect, useContext, useState, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { Button, ThemeContext, Theme } from '@duckma/react-ds'

import { ControlledField, ControlledForm } from '../../components/ControlledForm'
import { useRequest } from '../../hooks/useRequest'
import { api } from '../../data/api'
import ProfileLogo from '../../images/profile-logo.png'
import { Admin } from '../../data/models_users'
import { requiredValidator } from '../../utils/validators'
import { storage } from '../../data/storage'
import { SessionContext } from '../../components/MainLayout'
import { useDirty } from '../../hooks/useDirty'
import { useSuccessToast } from '../../hooks/useSuccessToast'
// import { PasswordField } from '../../components/PasswordField'
import _ from 'lodash'

export const ProfilePage = () => {
  const theme = useContext<Theme>(ThemeContext)
  const { mustReload } = useContext(SessionContext)
  const [request, { status, data }] = useRequest(api.getMe)
  const [updateProfile, { status: updateStatus, data: updateData }] = useRequest(api.updateMe)
  // const [updatePassword, { status: updatePasswordStatus }] = useRequest(api.updatePassword)

  const [values, setValues] = useState<Partial<Admin>>({})
  const [valid, setValid] = useState(true)
  // const [newPassword, setNewPassword] = useState('')
  // const [validPassword, setValidPassword] = useState(true)

  useEffect(() => {
    if (status === 'idle') {
      request({})
    }
  }, [request, status])

  useEffect(() => {
    if (updateStatus === 'success' && updateData) {
      // Update session user
      storage.saveUser(updateData)
      mustReload()
      request({})
    }
  }, [updateStatus, updateData, mustReload, request])

  useDirty(values, data, ['first_name', 'last_name'])

  useSuccessToast(updateStatus, 'Dati modificati con successo', '/me')
  // useSuccessToast(updatePasswordStatus, 'Password modificata con successo')

  // useEffect(() => {
  //   if (updatePasswordStatus === 'success') {
  //     setNewPassword('')
  //   }
  // }, [updatePasswordStatus])

  const dataObject = useMemo(() => _.omit(data, ['pics', 'accesses_count']), [data])
  const profileOnChange = useCallback((v, valid) => {
    setValues(v)
    setValid(valid)
  }, [])
  // const passwordOnChange = useCallback((v, valid) => {
  //   setNewPassword(v)
  //   setValidPassword(valid)
  // }, [])

  return (
    <Container>
      <FormContainer>
        <ControlledForm initialValues={data ? dataObject : null} onChange={profileOnChange}>
          <ControlledField name="nome" fieldName="first_name" validator={requiredValidator()} />
          <ControlledField name="cognome" fieldName="last_name" validator={requiredValidator()} />
        </ControlledForm>
        {/* <PasswordField value={newPassword} onChange={passwordOnChange} /> */}
        <ControlledForm initialValues={data ? dataObject : null} onChange={profileOnChange}>
          <ControlledField name="indirizzo-email" fieldName="email" disabled />
        </ControlledForm>
      </FormContainer>
      <div style={{ flexGrow: 1 }} />
      <FooterContainer>
        <PoweredBy>
          <Text color={theme['gray100']}>Powered By</Text>
          <a tabIndex={-1} href="https://duckma.com" target="_blank" rel="noopener noreferrer">
            <img
              src={ProfileLogo}
              alt="DuckMa Logo"
              width="106px"
              height="52px"
              style={{ marginLeft: '14px' }}
            />
          </a>
        </PoweredBy>
        <Button
          text="Salva"
          radius={4}
          disabled={status !== 'success' || !valid}
          loading={updateStatus === 'loading'}
          onClick={() => {
            const { first_name, last_name } = values
            if (first_name !== data?.first_name || last_name !== data?.last_name) {
              updateProfile({
                first_name: first_name!,
                last_name: last_name!,
              })
            }
            // if (newPassword !== '') {
            //   updatePassword({ password: newPassword })
            // }
          }}
          style={{ flexGrow: 0, marginLeft: 'auto', flexBasis: '20%' }}
        />
      </FooterContainer>
    </Container>
  )
}

const Container = styled.div`
  width: calc(100% - 108px);
  height: 100%;
  margin-left: 54px;
  margin-right: 54px;
  display: flex;
  flex-direction: column;
  justify-content: flex-between;
`

const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    flex-basis: 45%;
    margin-bottom: 20px;
  }
`

const FooterContainer = styled.div`
  display: flex;
  margin-bottom: 66px;
`

const Text = styled.div<{ color: string }>`
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  line-height: 24px;
  font-weight: 800;
  display: flex;
  align-items: center;
  color: ${(props) => props.color};
`

const PoweredBy = styled.div`
  display: flex;
  align-items: center;
`
