import React, { useState, useMemo, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Button, NumericInput } from '@duckma/react-ds'
import _ from 'lodash'

import { ControlledForm } from '../../components/ControlledForm'
import { useRequest } from '../../hooks/useRequest'
import { api, UpdateUser } from '../../data/api'
import { useParams } from 'react-router-dom'
import { useSuccessToast } from '../../hooks/useSuccessToast'
import { useDirty } from '../../hooks/useDirty'
import { ClubFields } from './components/ClubFields'
import { getUserType } from './utils/UserUtils'
import { Club, Manager, Artist } from '../../data/models_users'
import { ManagerFields } from './components/ManagerFields'
import { ArtistFields } from './components/ArtistFields'

import { storage } from '../../data/storage'

export const UserDetail = () => {
  const { id } = useParams<{ id: string }>()
  const [getUser, { status, data }] = useRequest(api.getUser)
  const [updateUser, { status: updateStatus }] = useRequest(api.updateUser)

  const [values, setValues] = useState<Partial<UpdateUser>>({})

  const initialValues = useMemo(
    () => (data ? { ..._.omit(data, ['pics', 'fake_reporting_count', 'accesses_count']) } : null),
    [data]
  )
  const onChange = useCallback((v, valid) => {
    setValues(v)
  }, [])

  const mustReload = useCallback(() => getUser({ id }), [getUser, id])

  useEffect(() => {
    if (status === 'idle') {
      mustReload()
    }
  }, [mustReload, status])

  useDirty(values, initialValues, ['first_name', 'last_name'])
  useSuccessToast(updateStatus, 'Dati modificati con successo')

  const userType = initialValues && getUserType(initialValues)

  return (
    <Container>
      <FormContainer
        onSubmit={(e) => {
          e.preventDefault()
          updateUser({
            id,
            body: _.pick(values, ['first_name', 'last_name']) as NonNullable<UpdateUser>,
          })
        }}
      >
        <FieldsContainer>
          <ControlledForm initialValues={initialValues} onChange={onChange}>
            {userType === 'club' && (
              <ClubFields club={initialValues as Club} mustReload={mustReload} />
            )}
            {userType === 'manager' && (
              <ManagerFields manager={initialValues as Manager} mustReload={mustReload} />
            )}
            {userType === 'artist' && <ArtistFields artist={initialValues as Artist} />}
          </ControlledForm>
          <NumericInput name="segnalato-come-fake" value={data?.fake_reporting_count} disabled />
          <NumericInput name="conteggio-accessi" value={data?.accesses_count} disabled />
        </FieldsContainer>
        <div style={{ flexGrow: 1 }} />
        <FooterContainer>
          <div style={{ flexGrow: 1 }} />
          {getUserType(storage.getUser()) === 'admin' && (
            <Button
              text="Salva"
              radius={4}
              loading={status === 'loading'}
              style={{ flexGrow: 0, marginLeft: 'auto', flexBasis: '20%' }}
            />
          )}
        </FooterContainer>
      </FormContainer>
    </Container>
  )
}

const Container = styled.div`
  width: calc(100% - 108px);
  height: 100%;
  margin-left: 54px;
  margin-right: 54px;
  display: flex;
  flex-direction: column;
  justify-content: flex-between;
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const FieldsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    flex-basis: 45%;
    margin-bottom: 20px;
  }
`

const FooterContainer = styled.div`
  align-self: flex-end;
  display: flex;
  width: 100%;
  margin-bottom: 66px;
`
