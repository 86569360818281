import React from 'react'
import { ThemeContext } from '@duckma/react-ds'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import { theme } from './styles/theme'
import { LoginPage } from './modules/auth/Login'
import './styles/global.css'
import { storage } from './data/storage'
import { UsersPage } from './modules/users/Users'
import { PasswordReset } from './modules/auth/PasswordReset'
import { PasswordOTP } from './modules/auth/PasswordOTP'
import { UpdatePassword } from './modules/auth/UpdatePassword'
import { MainLayout } from './components/MainLayout'
import { ProfilePage } from './modules/profile/Profile'
import { CreateUser } from './modules/users/CreateUser'
import { UserDetail } from './modules/users/UserDetail'
import { AppContextProvider } from './components/AppContext'
import { ArtistsPage } from './modules/users/Artists'
import { ManagersPage } from './modules/users/Managers'
import { ClubsPage } from './modules/users/Clubs'

const PrivateRoute = ({
  component: Component,
  header,
  ...rest
}: {
  component: React.ReactType
  header: React.ComponentProps<typeof MainLayout>
  [key: string]: any
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        storage.getToken() !== null ? (
          <MainLayout {...header}>
            <Component {...props} />
          </MainLayout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  )
}

function App() {
  return (
    <ThemeContext.Provider value={theme}>
      <AppContextProvider>
        <Router>
          <Switch>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/password-reset">
              <PasswordReset />
            </Route>
            <Route path="/password-otp">
              <PasswordOTP />
            </Route>
            <Route path="/update-password">
              <UpdatePassword />
            </Route>
            <Route exact path="/">
              <Redirect to="/users" />
            </Route>
            <PrivateRoute
              path="/users/create"
              component={CreateUser}
              header={{
                title: 'Nuovo Amministratore',
                backLink: {
                  text: 'Torna alla lista utenti',
                  to: '/users',
                },
              }}
            />
            <PrivateRoute
              path="/users/:id"
              component={UserDetail}
              header={{
                title: 'Dettaglio Utente',
                backLink: {
                  text: 'Torna alla lista utenti',
                  to: '/users',
                },
              }}
            />
            <PrivateRoute
              path="/users"
              component={UsersPage}
              header={{
                title: 'Utenti',
                headerButton: {
                  text: 'Nuovo Amministratore',
                  to: '/users/create',
                },
              }}
            />
            <PrivateRoute
              path="/artists"
              component={ArtistsPage}
              header={{
                title: 'Artisti',
              }}
            />
            <PrivateRoute
              path="/managers"
              component={ManagersPage}
              header={{
                title: 'Manager',
              }}
            />
            <PrivateRoute
              path="/clubs"
              component={ClubsPage}
              header={{
                title: 'Club',
              }}
            />
            <PrivateRoute
              path="/me"
              component={ProfilePage}
              header={{
                title: 'Profilo',
              }}
            />
          </Switch>
        </Router>
      </AppContextProvider>
    </ThemeContext.Provider>
  )
}

export default App
