import { Manager, User, ClubType } from '../../../data/models_users'
import { getUserType } from './UserUtils'
import { Formatter } from '../../../utils/formatters'

export const renderManagerClubTypes = (user: User): string | undefined => {
  if (getUserType(user) === 'manager') {
    return formatManagerClubTypes((user as Manager).club_types)
  } else return undefined
}

export const formatManagerClubTypes: Formatter = (clubTypes: ClubType[] = []) => {
  if (Array.isArray(clubTypes)) {
    return clubTypes
      .map((clubType): string => {
        switch (clubType) {
          case ClubType.DISCO:
            return 'Disco'
          case ClubType.LAP_DANCE:
            return 'Lap Dance'
          case ClubType.NIGHT_CLUB:
            return 'Night Club'
          case ClubType.SWINGER_CLUB:
            return 'Swinger Club'
          default:
            return ''
        }
      })
      .join(', ')
  } else {
    console.warn(
      `unable to format manager club types. Expected array but received ${typeof clubTypes}:`
    )
    console.log({ clubTypes })
    return ''
  }
}
