import React from 'react'
import { ColorName, Tag } from '@duckma/react-ds'

import { SubscriptionStatus } from '../../../data/models_users'

const tags: { [key in SubscriptionStatus]: { color: ColorName; label: string } } = {
  active: { color: 'success100', label: 'Attivo' },
  canceled: { color: 'danger100', label: 'Annullato' },
  incomplete: { color: 'warning100', label: 'Incompleto' },
  incomplete_expired: { color: 'danger100', label: 'Incompleto (scaduto)' },
  past_due: { color: 'danger100', label: 'Scaduto' },
  trialing: { color: 'warning100', label: 'Verifica in corso' },
  unpaid: { color: 'danger100', label: 'Non pagato' },
}

export const SubscriptionStatusTag: React.FC<{ status?: SubscriptionStatus }> = ({ status }) => (
  <Tag
    text={status ? tags[status].label : 'Nessuno'}
    color={status ? tags[status].color : 'gray100'}
  />
)
