import React, { useEffect, useState } from 'react'
import { DateTimePicker, Input } from '@duckma/react-ds'

import { api } from '../../../data/api'
import { ControlledField } from '../../../components/ControlledForm'
import { dateFormatter } from '../../../utils/formatters'
import { requiredValidator } from '../../../utils/validators'
import { Manager } from '../../../data/models_users'
import { formatUserAddress, formatUserGeoLocation } from '../utils/UserUtils'
import { formatManagerClubTypes } from '../utils/ManagerUtils'
import { toast } from 'react-toastify'
import { useRequest } from '../../../hooks/useRequest'
import { RequestAlert } from './RequestAlert'
import moment from 'moment'

import { getUserType } from '../utils/UserUtils'
import { storage } from '../../../data/storage'

export const ManagerFields: React.FC<{ manager: Manager; mustReload: () => unknown }> = ({
  manager,
  mustReload,
}) => {
  const [acceptEditRequest, { status }] = useRequest(api.acceptManagerNationalityEditRequest)
  const [acceptFreeTrialRequest, { status: freeTrialStatus }] = useRequest(
    api.acceptFreeTrialRequest
  )

  const [freeTrialDate, setFreeTrialDate] = useState<Date>(
    moment(new Date()).add(1, 'month').toDate()
  )
  useEffect(() => {
    if (status === 'success') {
      toast.success('Richiesta accettata con successo')
      mustReload()
    }
  }, [mustReload, status])

  useEffect(() => {
    if (freeTrialStatus === 'success') {
      toast.success('Richiesta accettata con successo')
      mustReload()
    }
  }, [freeTrialStatus, mustReload])

  return (
    <React.Fragment>
      <RequestAlert
        visible={manager.manager_nationality_edit_requested_at}
        onAccept={() => acceptEditRequest({ id: manager.id })}
      >
        Il manager{' '}
        <b>
          {manager.first_name} {manager.last_name}
        </b>{' '}
        ha richiesto di poter cambiare la propria <b>nazionalità</b>
      </RequestAlert>
      <RequestAlert
        visible={manager.free_trial_requested_at}
        onAccept={() => acceptFreeTrialRequest({ id: manager.id, date: freeTrialDate })}
      >
        Il manager{' '}
        <b>
          {manager.first_name} {manager.last_name}
        </b>{' '}
        ha richiesto di poter attivare una <b>prova gratuita</b>
        <DateTimePicker
          name="Fino a"
          locale="it"
          dateFormat="dd/MM/yyyy"
          selected={freeTrialDate}
          onChange={setFreeTrialDate}
        />
      </RequestAlert>

      <ControlledField
        name="nome"
        fieldName="first_name"
        validator={requiredValidator()}
        disabled
      />
      <ControlledField
        name="cognome"
        fieldName="last_name"
        validator={requiredValidator()}
        disabled
      />
      <ControlledField
        name="azienda"
        fieldName="company"
        validator={requiredValidator()}
        disabled
      />
      <ControlledField
        name="tipologia-club"
        fieldName="club_types"
        validator={requiredValidator()}
        disabled
        formatter={formatManagerClubTypes}
      />
      <ControlledField name="numero-di-telefono" fieldName="phone_number" disabled />

      <Input
        name="nazione"
        disabled={getUserType(storage.getUser()) !== 'admin'}
        value={formatUserAddress(manager, 'country')}
      />
      <Input
        name="regione"
        disabled={getUserType(storage.getUser()) !== 'admin'}
        value={formatUserAddress(manager, 'state')}
      />
      <Input
        name="città"
        disabled={getUserType(storage.getUser()) !== 'admin'}
        value={formatUserAddress(manager, 'city')}
      />
      <ControlledField
        name="data-di-iscrizione"
        fieldName="created_at"
        disabled
        formatter={dateFormatter}
      />
      <Input name="ultima-posizione" value={formatUserGeoLocation(manager)} disabled />
      <ControlledField name="rinnovo-sottoscrizioni" fieldName="subscriptions_renewals" disabled />
    </React.Fragment>
  )
}
