/**
 * This file defines various input formatters of general use.
 * Single use functions shouldn't be put here.
 */
import moment from 'moment'

export type Formatter = (value: any) => string

export const dateFormatter: Formatter = (date: string) => moment(date).format('DD/MM/YYYY')
export const dateTimeFormatter: Formatter = (date: string) =>
  moment(date).format('DD/MM/YYYY HH:mm')
