import {
  Content,
  Notification,
  Tag,
  Media,
  Event,
  EventType,
  EventStatus,
  Dashboard,
} from './models'
import { storage } from './storage'
import _ from 'lodash'
import { User, Admin } from './models_users'

class API {
  private request = async <T>(
    method: string,
    path: string,
    body?: {},
    params?: { [key: string]: string | string[] },
    useToken: boolean = true
  ) => {
    let uri = process.env.REACT_APP_BASE_URL + path
    if (_.keys(params).length > 0) {
      uri +=
        '?' +
        _.map(params, (value, key) =>
          Array.isArray(value) ? value.map((v) => `${key}=${v}`).join('&') : `${key}=${value}`
        ).join('&')
    }
    return fetch(uri, {
      method,
      body: body ? JSON.stringify(body) : undefined,
      headers: this.getHeaders(useToken),
    }).then((res) => this.handleResponse<T>(res))
  }

  private handleResponse = <T>(response: Response) => {
    if (response.status !== 200) {
      console.error(`Error while sending request. Code: ${response.status}`)
    }
    if (response.status === 401) {
      this.clearSession()
      window.location.reload()
    }
    return response.json().then((json: { data: T | null; error: null | { code: string } }) => {
      if (response.status !== 200) {
        throw new Error(json.error!.code || 'invalid_server_response')
      }
      return json.data!
    })
  }

  private getHeaders = (useToken: boolean) => {
    const token = storage.getToken()
    return {
      ...(useToken && token ? { Authorization: `Bearer ${token}` } : undefined),
      Platform: 'console',
      'User-Language': navigator?.language,
      'Content-Type': 'application/json',
    }
  }

  private get = async <T>(
    path: string,
    params: { [key: string]: string | string[] },
    useToken: boolean = true
  ) => this.request<T>('GET', path, undefined, params, useToken)

  private delete = async <T>(
    path: string,
    params: { [key: string]: string },
    useToken: boolean = true
  ) => this.request<T>('DELETE', path, undefined, params, useToken)

  private post = async <T>(path: string, body: {}, useToken: boolean = true) =>
    this.request<T>('POST', path, body, undefined, useToken)

  private put = async <T>(path: string, body: {}, useToken: boolean = true) =>
    this.request<T>('PUT', path, body, undefined, useToken)

  private patch = async <T>(path: string, body: {}, useToken: boolean = true) =>
    this.request<T>('PATCH', path, body, undefined, useToken)

  private cleanParams = (params: {
    [key: string]: string | number | null | undefined | string[]
  }) =>
    _(params)
      .pickBy((value) => value !== null && value !== undefined && value !== '')
      .mapValues((param) => {
        if (Array.isArray(param)) {
          return param.map((p) => String(p))
        }
        return String(param)
      })
      .value()

  private persistSession = (res: LoginResponse) => {
    storage.saveToken(res.token)
    storage.saveUser(res.user)
    return res
  }

  public clearSession = () => {
    storage.deleteToken()
    storage.deleteUser()
  }

  public login = ({ email, password }: { email: string; password: string }) =>
    this.post<LoginResponse>('/auth/login', { email, password }, false)
      .then((data) => {
        if (['admin', 'super_admin'].includes(data.user.role)) {
          return data
        }
        throw new Error('insufficient_role')
      })
      .then(this.persistSession)

  public recoverPassword = ({ email }: { email: string }) =>
    this.post<{}>('/auth/otp/request', { email }, false)

  public loginOTP = (body: { email: string; otp: string }) =>
    this.post<LoginResponse>('/auth/otp/login', body, false).then(this.persistSession)

  public updatePassword = ({
    password,
    session,
  }: {
    password: string
    session?: LoginResponse
  }) => {
    if (session) {
      // save this one in localStorage before requesting
      this.persistSession(session)
    }
    return this.put<User>('/me/password_via_otp', { password }).catch((err) => {
      if (session) {
        this.clearSession()
      }
      throw err
    })
  }

  public getMe = () => this.get<Admin>('/me', {})

  public updateMe = (body: UpdateUser) => this.patch<User>('/me', body)

  public getUsers = (params: ListParams) =>
    this.get<PaginatedResponse<User>>('/users', this.cleanParams(params))

  public getUser = (params: { id: string }) => this.get<User>(`/users/${params.id}`, {})

  public createUser = (body: CreateUser) => this.post<User>('/users', body)

  public updateUser = ({ id, body }: { id: string; body: UpdateUser }) =>
    this.patch<User>(`/users/${id}`, body)

  public deleteUser = (params: { id: string }) => this.delete<User>(`/users/${params.id}`, {})

  public getContents = (params: ListParams) =>
    this.get<PaginatedResponse<Content>>('/contents', this.cleanParams(params))

  public getContent = (params: { id: string }) => this.get<Content>(`/contents/${params.id}`, {})

  public createContent = (body: CreateContent) => this.post<Content>('/contents', body)

  public updateContent = ({ id, body }: { id: string; body: UpdateContent }) =>
    this.patch<Content>(`/contents/${id}`, body)

  public deleteContent = (params: { id: string }) =>
    this.delete<Content>(`/contents/${params.id}`, {})

  public getNotifications = (params: ListParams) =>
    this.get<PaginatedResponse<Notification>>('/notifications', this.cleanParams(params))

  public getNotification = (params: { id: string }) =>
    this.get<Notification>(`/notifications/${params.id}`, {})

  public createNotification = (body: CreateNotification) =>
    this.post<Notification>('/notifications', body)

  public updateNotification = ({ id, body }: { id: string; body: UpdateNotification }) =>
    this.patch<Notification>(`/notifications/${id}`, body)

  public deleteNotification = (params: { id: string }) =>
    this.delete<Notification>(`/notifications/${params.id}`, {})

  public sendNotification = (params: { id: string }) =>
    this.post<Notification>(`/notifications/${params.id}/send`, {})

  public getContentsByTitle = (title: string, status = 'published') =>
    this.get<PaginatedResponse<Pick<Content, 'id' | 'title'>>>(
      '/contents',
      this.cleanParams({ title, page: 0, page_size: 10, status, format: 'minimal' })
    )

  public getUsersBySearch = (search: string, ignore_ids: string[] = []) =>
    this.get<PaginatedResponse<Pick<User, 'id' | 'email'>>>(
      '/users',
      this.cleanParams({
        search_text: search,
        page: 0,
        page_size: 10,
        format: 'minimal',
        ignore_ids,
      })
    )

  public getTags = (params: ListParams) =>
    this.get<PaginatedResponse<Tag>>('/tags', this.cleanParams(params))

  public getTagsBySearch = (search: string, ignore_ids: string[] = []) =>
    this.getTags({
      search_text: search,
      page: 0,
      page_size: 10,
      format: 'minimal',
      order_by: 'name',
      order_direction: 'asc',
      ignore_ids,
    })

  public getTag = (params: { id: string }) => this.get<Tag>(`/tags/${params.id}`, {})

  public createTag = (body: CreateTag) => this.post<Tag>('/tags', body)

  public updateTag = ({ id, body }: { id: string; body: UpdateTag }) =>
    this.patch<Tag>(`/tags/${id}`, body)

  public deleteTag = (params: { id: string }) => this.delete<Tag>(`/tags/${params.id}`, {})

  public getMedias = (params: ListParams) =>
    this.get<PaginatedResponse<Media>>('/media', this.cleanParams(params))

  public getMedia = (params: { id: string }) => this.get<Media>(`/media/${params.id}`, {})

  public createMedia = (body: CreateMedia) => this.post<Media>('/media', body)

  public updateMedia = ({ id, body }: { id: string; body: UpdateMedia }) =>
    this.patch<Media>(`/media/${id}`, body)

  public deleteMedia = (params: { id: string }) => this.delete<Media>(`/media/${params.id}`, {})

  public getEvents = (
    params: ListParams & { date?: string; from?: string; to?: string; statuses?: EventStatus[] }
  ) => this.get<PaginatedResponse<Event>>('/events', this.cleanParams(params))

  public getEvent = (params: { id: string }) => this.get<Event>(`/events/${params.id}`, {})

  public createEvent = (body: CreateEvent) => this.post<Event>('/events', body)

  public acceptArtistNationalityEditRequest = ({ id }: { id: string }) =>
    this.post<Event>(`/users/${id}/accept-artist-edit-nationality-request`, {})
  public acceptManagerNationalityEditRequest = ({ id }: { id: string }) =>
    this.post<Event>(`/users/${id}/accept-manager-edit-nationality-request`, {})
  public acceptClubAddressEditRequest = ({ id }: { id: string }) =>
    this.post<Event>(`/users/${id}/accept-club-edit-address-request`, {})
  public acceptClubTypeEditRequest = ({ id }: { id: string }) =>
    this.post<Event>(`/users/${id}/accept-club-edit-type-request`, {})
  public acceptFreeTrialRequest = ({ id, date }: { id: string; date: Date }) =>
    this.post<Event>(`/users/${id}/accept-free-trial-request`, { date })

  public updateEvent = ({ id, body }: { id: string; body: UpdateEvent }) =>
    this.patch<Event>(`/events/${id}`, body)

  public updateEventStatus = ({ id, status }: { id: string; status: EventStatus }) =>
    this.patch<Event>(`/events/${id}/status`, { status })

  public deleteEvent = (params: { id: string }) => this.delete<Event>(`/events/${params.id}`, {})

  public getEventTypes = (params: ListParams) =>
    this.get<PaginatedResponse<EventType>>('/events/types', this.cleanParams(params))

  public getEventType = (params: { id: string }) =>
    this.get<EventType>(`/events/types/${params.id}`, {})

  public createEventType = (body: CreateEventType) => this.post<EventType>('/events/types', body)

  public updateEventType = ({ id, body }: { id: string; body: UpdateEventType }) =>
    this.patch<EventType>(`/events/types/${id}`, body)

  public deleteEventType = (params: { id: string }) =>
    this.delete<EventType>(`/events/types/${params.id}`, {})

  public getEventTypesByTitle = (search_text: string) =>
    this.get<PaginatedResponse<EventType>>(
      '/events/types',
      this.cleanParams({ search_text, page: 0, page_size: 10, format: 'minimal' })
    )

  public getDashboard = (params: {}) => this.get<Dashboard>(`/dashboard`, {})

  public getChatToken = () => this.get<string>('/chat/adminToken', {})

  public createVideoRoom = ({ userId }: { userId: string }) =>
    this.post<{ token: string; sid: string }>(`/chat/video/rooms/${userId}`, {})
}

export type LoginResponse = { token: string; user: Admin }

type PaginatedResponse<T> = {
  items: T[]
  pagination: { current_page: number; total_pages: number; page_size: number }
}

type ListParams = {
  search_text?: string
  order_by?: string
  order_direction?: 'asc' | 'desc'
  page?: number
  page_size?: number
  [key: string]: string | number | undefined | string[]
}

export type CreateUser = Pick<Admin, 'first_name' | 'last_name' | 'email' | 'role'> & {
  password: string
  provider: string
}

export type UpdateUser = Pick<Admin, 'first_name' | 'last_name'>

export type CreateContent = Pick<
  Content,
  'title' | 'subtitle' | 'status' | 'description' | 'external_links' | 'tags'
> & { images: { id: string; caption: string }[] }

type UpdateContent = CreateContent

export type CreateMedia = { title: string; base64: string; type: 'image' }
type UpdateMedia = Pick<CreateMedia, 'title'>

export type CreateNotification = Pick<
  Notification,
  'title' | 'message' | 'category' | 'scheduled_at' | 'type'
> & {
  content: string | null
  users: string[]
}

export type UpdateNotification = CreateNotification

export type CreateTag = Pick<Tag, 'name' | 'color'>
type UpdateTag = CreateTag

export type CreateEvent = Partial<
  Pick<Event, 'title' | 'period' | 'permissions'> & { type: string }
>
export type UpdateEvent = CreateEvent

export type CreateEventType = Pick<EventType, 'name' | 'color'>
type UpdateEventType = CreateEventType

export const api = new API()
