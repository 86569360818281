import React from 'react'
import styled from 'styled-components'
import { Button } from '@duckma/react-ds'

export const RequestAlert: React.FC<{ visible?: any; onAccept: () => unknown }> = ({
  visible = true,
  onAccept,
  children,
}) => {
  return visible ? (
    <RequestsForm>
      <UserRequest>{children}</UserRequest>
      <AcceptRequestButton
        size="large"
        radius={10}
        text="Accetta"
        type="button"
        onClick={onAccept}
      />
    </RequestsForm>
  ) : (
    <></>
  )
}

const RequestsForm = styled.div`
  width: 100%;
  padding: 10px 16px 10px 0;
  flex-basis: 100% !important;
  display: flex;
  flex-flow: wrap;
`

const UserRequest = styled.p`
  font-size: 16px;
  font-family: open sans;
  width: 30%;
  margin-right: 40px;
  line-height: 23px;
`

const AcceptRequestButton = styled(Button)`
  width: 120px;
  height: 50px;
`
