import { Column, Ordering } from '@duckma/react-ds'
import _ from 'lodash'

import {
  Artist,
  User,
  ArtistType,
  Sex,
  EyesColor,
  SkinColor,
  UserType,
  ShoesSize,
} from '../../../data/models_users'
import { getUserType, formatUserGeoLocation } from './UserUtils'
import { dateFormatter, dateTimeFormatter, Formatter } from '../../../utils/formatters'

export const getArtistParameters = (
  page: number,
  search: string | undefined,
  ordering: Ordering<User>
) => {
  return {
    type: [UserType.ARTIST],
    order_by: ordering.key,
    order_direction: ordering.direction,
    search_text: search,
    page,
    page_size: 10,
  }
}
export const getArtistColumns = (): Array<Column<Artist>> => {
  return [
    { key: 'first_name', name: 'Nome', orderable: true },
    { key: 'last_name', name: 'Cognome', orderable: true },
    { key: 'artist_sex', name: 'Gender', orderable: true, render: renderArtistSex },

    {
      key: 'types',
      name: 'Tipologia',
      orderable: false,
      render: renderArtistTypes,
    },
    {
      key: 'nationality',
      name: 'Nazionalità',
    },
    {
      key: 'person_country',
      name: 'Nazione',
    },
    { key: 'languages', name: 'Lingue', orderable: false, render: renderArtistLanguages },
    {
      key: 'birthday',
      name: 'Data di nascita',
      orderable: false,
      render: renderArtistBirthday,
    },
    { key: 'height_cm', name: 'Altezza', orderable: true, render: renderArtistHeight },
    { key: 'eyes_color', name: 'Occhi', orderable: true, render: renderArtistEyeColor },
    { key: 'weight_kg', name: 'Peso', orderable: true, render: renderArtistWeight },
    { key: 'clothes_size', name: 'Taglia', orderable: true, render: renderArtistClothesSize },
    { key: 'skin_color', name: 'Pelle', orderable: true, render: renderArtistSkinColor },
    { key: 'shoes_size', name: 'Calzatura', orderable: true, render: renderArtistShoeSize },
    { key: 'favorite_stylist', name: 'Stilista', orderable: true, render: renderArtistStylist },
    { key: 'favorite_parfume', name: 'Profumo', orderable: true, render: renderArtistParfume },
    {
      key: 'last_location',
      name: 'Posizione',
      orderable: false,
      render: (user) => formatUserGeoLocation(user),
    },
    {
      key: 'created_at',
      name: 'Data Creazione',
      orderable: true,
      render: (user) => dateTimeFormatter(user.created_at),
    },
  ] as Column<Artist>[]
}

const renderArtistSex = (user: User): string | undefined => {
  if ('artist' === getUserType(user)) {
    return formatArtistSex((user as Artist).artist_sex)
  }
}

const renderArtistTypes = (user: User): string | undefined => {
  if ('artist' === getUserType(user)) {
    return formatArtistTypes((user as Artist).types)
  }
}

const renderArtistLanguages = (user: User): string | undefined => {
  if ('artist' === getUserType(user)) {
    return formatArtistLanguages((user as Artist).languages)
  }
}

const renderArtistBirthday = (user: User): string | undefined => {
  if ('artist' === getUserType(user)) {
    let birthday = (user as Artist).birthday
    return birthday ? dateFormatter(birthday) : undefined
  }
}

const renderArtistHeight = (user: User): string | undefined => {
  if ('artist' === getUserType(user)) {
    let height = (user as Artist).height_cm
    return formatArtistHeight(height)
  }
}

const renderArtistEyeColor = (user: User): string | undefined => {
  if ('artist' === getUserType(user)) {
    return formatArtistEyesColor((user as Artist).eyes_color)
  }
}

const renderArtistWeight = (user: User): string | undefined => {
  if ('artist' === getUserType(user)) {
    let weight = (user as Artist).weight_kg
    return `${weight} kg`
  }
}

const renderArtistClothesSize = (user: User): string | undefined => {
  if ('artist' === getUserType(user)) {
    return (user as Artist).clothes_size
  }
}

const renderArtistSkinColor = (user: User): string | undefined => {
  if ('artist' === getUserType(user)) {
    return formatArtistSkinColor((user as Artist).skin_color)
  }
}

const renderArtistShoeSize = (user: User): string | undefined => {
  if ('artist' === getUserType(user)) {
    let size = (user as Artist).shoes_size
    return formatArtistShoesSize(size)
  }
}

const renderArtistStylist = (user: User): string | undefined => {
  if ('artist' === getUserType(user)) {
    return (user as Artist).favorite_stylist
  }
}

const renderArtistParfume = (user: User): string | undefined => {
  if ('artist' === getUserType(user)) {
    return (user as Artist).favorite_parfume
  }
}

export const formatArtistSex: Formatter = (artistSex: Sex) => {
  switch (artistSex) {
    case Sex.MALE:
      return 'Maschio'
    case Sex.FEMALE:
      return 'Femmina'
    default:
      return ''
  }
}

export const formatArtistTypes: Formatter = (artistTypes: ArtistType[] = []) => {
  if (Array.isArray(artistTypes)) {
    return artistTypes
      .map((artistType): string => {
        switch (artistType) {
          case ArtistType.MUSICIAN:
            return 'Musicista'
          case ArtistType.SINGER:
            return 'Cantante'
          default:
            return _.capitalize(artistType)
        }
      })
      .join(', ')
  } else {
    console.warn(
      `unable to format artist types. Expected array but received ${typeof artistTypes}:`
    )
    console.log({ artistTypes })
    return ''
  }
}

export const formatArtistLanguages: Formatter = (languages: ArtistType[] = []) => {
  if (Array.isArray(languages)) {
    return languages.map((language): string => language).join(', ')
  } else {
    console.warn(
      `unable to format artist languages. Expected array but received ${typeof languages}:`
    )
    console.log({ languages })
    return ''
  }
}

export const formatArtistHeight: Formatter = (heightCm: number) => {
  if (heightCm) {
    const meters = Math.floor(heightCm / 100)
    const cm = Math.floor(heightCm % 100)
    return `${meters}.${cm} m`
  } else return ''
}

export const formatArtistEyesColor: Formatter = (eyesColor: EyesColor) => {
  switch (eyesColor) {
    case EyesColor.BLACK:
      return 'Neri'
    case EyesColor.BLUE:
      return 'Blu'
    case EyesColor.BROWN:
      return 'Marroni'
    case EyesColor.GREEN:
      return 'Verdi'
    case EyesColor.GREY:
      return 'Grigi'
    case EyesColor.LIGHT_BLUE:
      return 'Azzurri'
    default:
      return ''
  }
}

export const formatArtistWeight: Formatter = (weightKg: number) => {
  if (weightKg) {
    return `${weightKg} kg`
  } else return ''
}

export const formatArtistSkinColor: Formatter = (skinColor: SkinColor) => {
  switch (skinColor) {
    case SkinColor.DARK:
      return 'Scura'
    case SkinColor.LIGHT:
      return 'Chiara'
    case SkinColor.MEDIUM_DARK:
      return 'Scura intermedia'
    case SkinColor.MEDIUM_LIGHT:
      return 'Chiara intermedia'
    case SkinColor.VERY_DARK:
      return 'Molto scura'
    case SkinColor.VERY_LIGHT:
      return 'Molto chiara'
    default:
      return ''
  }
}

export const formatArtistShoesSize: Formatter = (shoesSize: ShoesSize) => {
  if (shoesSize) {
    return `${shoesSize.value} ${shoesSize.measure_unit}`
  } else return ''
}
