import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Table, Ordering, Button, Paginator } from '@duckma/react-ds'

import { api } from '../../data/api'
import { useRequest } from '../../hooks/useRequest'
import { Artist, User } from '../../data/models_users'
import { Column, Subtitle } from '@duckma/react-ds'
import { toast } from 'react-toastify'
import { SearchField } from '../../components/SearchField'
import { SkeletonTable } from '../../components/SkeletonTable'
import { getArtistColumns, getArtistParameters } from './utils/ArtistUtils'
import _ from 'lodash'

export const ArtistsPage = () => {
  const history = useHistory()
  const [getUsers, { status, data }] = useRequest(api.getUsers)
  const [deleteUser, { data: deleteData }, resetDelete] = useRequest(api.deleteUser)

  const [ordering, setOrdering] = useState<Ordering<User>>({
    key: 'created_at',
    direction: 'asc',
  })
  const [search, setSearch] = useState<string | undefined>(undefined)
  const [page, setPage] = useState<number>(0)

  const getUsersWithParams = useCallback(
    () => getUsers(getArtistParameters(page, search, ordering)),
    [getUsers, ordering, page, search]
  )

  useEffect(() => {
    getUsersWithParams()
  }, [getUsersWithParams])

  useEffect(() => {
    if (deleteData != null) {
      toast('Utente eliminato con successo', { type: 'success' })
      getUsersWithParams()
      resetDelete()
    }
  }, [deleteData, resetDelete, getUsersWithParams])

  const columns = useMemo(
    () =>
      _.reverse(
        _.reverse(getArtistColumns()).concat([
          {
            key: 'artist_nationality_edit_requested_at',
            orderable: true,
            name: 'Richieste',
            render: (user) => (
              <Actions style={{ width: '50px' }}>
                {(user as Artist).artist_nationality_edit_requested_at && (
                  <Button
                    radius={24}
                    size="icon"
                    iconLeft="warning"
                    color="warning"
                    onClick={() => {
                      history.push('/users/' + user.id)
                    }}
                  />
                )}
              </Actions>
            ),
          },
          { key: 'accesses_count', name: 'Accessi', orderable: true },
          {
            key: 'actions',
            name: 'Azioni',
            orderable: false,
            render: (user) => (
              <Actions>
                <Button
                  size="icon"
                  iconLeft="info"
                  color="primary"
                  radius={24}
                  onClick={() => {
                    history.push('/users/' + user.id)
                  }}
                />
                <Button
                  size="icon"
                  iconLeft="trash"
                  color="danger"
                  radius={24}
                  onClick={() => {
                    const conf = window.confirm(
                      `Sei sicuro di voler cancellare l'utente (${user.email})? L'azione è irreversibile.`
                    )
                    if (conf) {
                      deleteUser({ id: user.id })
                    }
                  }}
                />
              </Actions>
            ),
          },
        ] as Column<Artist>[])
      ) as Column<User>[],
    [deleteUser, history]
  )

  return (
    <Container>
      <SearchField
        style={{ alignSelf: 'flex-end', marginBottom: '35px' }}
        onSearch={(search) => {
          if (page !== 0) {
            setPage(0)
          }
          setSearch(search)
        }}
      />

      {status !== 'loading' ? (
        <TableContainer>
          <Table
            columns={columns}
            records={data ? data.items : []}
            order={ordering}
            onOrder={setOrdering}
          />
        </TableContainer>
      ) : (
        <SkeletonTable />
      )}
      {status === 'success' && data && data.items.length > 0 && (
        <Paginator
          style={{ marginTop: '75px', marginBottom: '75px' }}
          currentPage={page}
          totalPages={data.pagination.total_pages}
          onPageSelect={setPage}
        />
      )}
      {status === 'success' && data && data.items.length === 0 && (
        <Subtitle text="Nessun utente trovato" color="gray100" />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 50px;
  padding-right: 50px;
`

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  * {
    margin-right: 16px;
  }
`

const TableContainer = styled.div`
  min-width: 200px;
  background-color: #37afb8;
  max-width: calc(100vw - 280px);
  overflow-x: scroll;
  margin-right: 10px;
  margin-left: 10px;
  border: 1px solid #cacaca;
  border-radius: 10px;
  th {
    color: #fff !important;
  }
`
