import {
  Artist,
  Manager,
  Admin,
  User,
  Club,
  UserRole,
  UserType,
  Address,
  GeoPoint,
} from '../../../data/models_users'
import { Formatter } from '../../../utils/formatters'

export const castUser = (user: User): Admin | Artist | Club | Manager | undefined => {
  switch (getUserType(user)) {
    case 'admin':
      return user as Admin
    case 'artist':
      return user as Artist
    case 'club':
      return user as Club
    case 'manager':
      return user as Manager
    default:
      return undefined
  }
}

export const getUserType = (
  user: Pick<User, 'role' | 'type'>
): 'super_admin' | 'admin' | 'artist' | 'club' | 'manager' | undefined => {
  if (user.role === UserRole.SUPER_ADMIN) {
    return 'super_admin'
  } else if (user.role === UserRole.ADMIN) {
    return 'admin'
  } else {
    switch (user.type) {
      case UserType.ARTIST:
        return 'artist'
      case UserType.CLUB:
        return 'club'
      case UserType.MANAGER:
        return 'manager'
    }
  }
}

export const getUserAddress = (user: User): Address | undefined => {
  switch (getUserType(user)) {
    case 'artist':
      return (user as Artist).address
    case 'club':
      return (user as Club).address
    case 'manager':
      return (user as Manager).address
  }
}

export const formatUserAddress = (
  user: User,
  content: 'full' | 'country' | 'state' | 'city' = 'full'
) => {
  const address = getUserAddress(user)
  if (address) {
    switch (content) {
      case 'full':
        const list = [address.country?.name, address.state?.name, address.city?.name]
        return list.join(', ')
      default:
        return address[content]?.name
    }
  }
}

const getUserGeoLocation = (user: User): GeoPoint | undefined => {
  const userType = getUserType(user)
  let location = undefined
  if (userType === 'artist') {
    location = (user as Artist).last_location
  }
  if (userType === 'manager') {
    location = (user as Manager).last_location
  }
  return location
}

export const formatUserGeoLocation: Formatter = (user: User) => {
  const location = getUserGeoLocation(user)
  if (location) {
    return `${location.latitude}, ${location.longitude}`
  }
  return ''
}
