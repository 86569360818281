import { Club, User, ClubType, Day } from '../../../data/models_users'
import { getUserType } from './UserUtils'
import { Formatter } from '../../../utils/formatters'

export const renderClubType = (user: User): string | undefined => {
  if (getUserType(user) === 'club') {
    return formatClubType((user as Club).club_type)
  }
}

export const renderClubBusinessDays = (user: User): string | undefined => {
  if (getUserType(user) === 'club') {
    let businessDays = (user as Club).business_days
    return formatClubBusinessDays(businessDays)
  }
}

export const formatClubType: Formatter = (type: ClubType) => {
  switch (type) {
    case ClubType.DISCO:
      return 'Disco'
    case ClubType.LAP_DANCE:
      return 'Lap Dance'
    case ClubType.NIGHT_CLUB:
      return 'Night Club'
    case ClubType.SWINGER_CLUB:
      return 'Swinger Club'
    default:
      return ''
  }
}

export const formatClubBusinessDays: Formatter = (businessDays: Day[] = []) => {
  if (Array.isArray(businessDays)) {
    return businessDays
      .map((day): string => {
        switch (day) {
          case Day.MONDAY:
            return 'Lu'
          case Day.TUESDAY:
            return 'Ma'
          case Day.WEDNESDAY:
            return 'Me'
          case Day.THURSDAY:
            return 'Gi'
          case Day.FRIDAY:
            return 'Ve'
          case Day.SATURDAY:
            return 'Sa'
          case Day.SUNDAY:
            return 'Do'
          default:
            return ''
        }
      })
      .join(', ')
  } else {
    console.warn(
      `unable to format club business days. Expected array but received ${typeof businessDays}:`
    )
    console.log({ businessDays })
    return ''
  }
}
