import React, { useEffect, useState } from 'react'
import { DateTimePicker, Input } from '@duckma/react-ds'
import { toast } from 'react-toastify'

import { api } from '../../../data/api'
import { ControlledField } from '../../../components/ControlledForm'
import { dateFormatter } from '../../../utils/formatters'
import { requiredValidator } from '../../../utils/validators'
import { formatClubType, formatClubBusinessDays } from '../utils/ClubUtils'
import { Club } from '../../../data/models_users'
import { formatUserAddress } from '../utils/UserUtils'
import { useRequest } from '../../../hooks/useRequest'
import { RequestAlert } from './RequestAlert'
import moment from 'moment'

import { getUserType } from '../utils/UserUtils'
import { storage } from '../../../data/storage'

export const ClubFields: React.FC<{ club: Club; mustReload: () => unknown }> = ({
  club,
  mustReload,
}) => {
  const [acceptAddressEditRequest, { status: addressStatus }] = useRequest(
    api.acceptClubAddressEditRequest
  )
  const [acceptTypeEditRequest, { status: typeStatus }] = useRequest(api.acceptClubTypeEditRequest)
  const [acceptFreeTrialRequest, { status: freeTrialStatus }] = useRequest(
    api.acceptFreeTrialRequest
  )

  const [freeTrialDate, setFreeTrialDate] = useState<Date>(
    moment(new Date()).add(1, 'month').toDate()
  )
  useEffect(() => {
    if (addressStatus === 'success') {
      toast.success('Richiesta accettata con successo')
      mustReload()
    }
  }, [addressStatus, mustReload])

  useEffect(() => {
    if (typeStatus === 'success') {
      toast.success('Richiesta accettata con successo')
      mustReload()
    }
  }, [mustReload, typeStatus])

  useEffect(() => {
    if (freeTrialStatus === 'success') {
      toast.success('Richiesta accettata con successo')
      mustReload()
    }
  }, [freeTrialStatus, mustReload])

  return (
    <React.Fragment>
      <RequestAlert
        visible={club.club_address_edit_requested_at}
        onAccept={() => acceptAddressEditRequest({ id: club.id })}
      >
        Il club <b>{club.name}</b> ha richiesto di poter cambiare il proprio <b>indirizzo</b>
      </RequestAlert>
      <RequestAlert
        visible={club.club_type_edit_requested_at}
        onAccept={() => acceptTypeEditRequest({ id: club.id })}
      >
        Il club <b>{club.name}</b> ha richiesto di poter cambiare il proprio <b>tipo</b>
      </RequestAlert>
      <RequestAlert
        visible={club.free_trial_requested_at}
        onAccept={() => acceptFreeTrialRequest({ id: club.id, date: freeTrialDate })}
      >
        Il club <b>{club.name}</b> ha richiesto di poter attivare una <b>prova gratuita</b>
        <DateTimePicker
          name="Fino a"
          locale="it"
          dateFormat="dd/MM/yyyy"
          selected={freeTrialDate}
          onChange={setFreeTrialDate}
        />
      </RequestAlert>

      <ControlledField name="nome" fieldName="name" validator={requiredValidator()} disabled />
      <ControlledField
        name="tipologia"
        fieldName="club_type"
        validator={requiredValidator()}
        disabled
        formatter={formatClubType}
      />
      <ControlledField name="numero-di-telefono" fieldName="phone_number" disabled />
      <Input
        name="giorni-di-apertura"
        disabled
        value={formatClubBusinessDays(club.business_days)}
      />
      <Input
        name="nazione"
        disabled={getUserType(storage.getUser()) !== 'admin'}
        value={formatUserAddress(club, 'country')}
      />
      <Input
        name="regione"
        disabled={getUserType(storage.getUser()) !== 'admin'}
        value={formatUserAddress(club, 'state')}
      />
      <Input
        name="città"
        disabled={getUserType(storage.getUser()) !== 'admin'}
        value={formatUserAddress(club, 'city')}
      />
      <ControlledField
        name="data-di-iscrizione"
        fieldName="created_at"
        disabled
        formatter={dateFormatter}
      />
      <ControlledField name="rinnovo-sottoscrizioni" fieldName="subscriptions_renewals" disabled />
    </React.Fragment>
  )
}
